<template>

  <div class="main-wrapper">
    <div class="claim-wrapper">
      <div class="verify-div">
        <div class="img-dev">
          <img class="img1" :src="portal28">
          <p class="verify-t">{{ $t('ebClaim.VerificationCode') }}</p>
          <p class="verify-s">{{ $t('ebClaim.VerificationCodeText') }}</p>
        </div>

        <PasswordInput
          :value="value"
          :length="4"
          :gutter="10"
          :mask="false"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
        />

        <NumberKeyboard
          v-model="value"
          :maxlength="4"
          :show="showKeyboard"
          @blur="showKeyboard = false"
        />
        <div class="otp-sent-dev">
          <p v-if="isCountDown"><span>Not receive? </span><span class="count-down-number"> 00:{{ countdownVal }}</span></p>
          <p v-else><span class="resent" @click="sendOtp">{{ $t('register.reSend') }}</span></p>
        </div>

        <div class="btn-group">
          <div class="verify-agree">
            <Checkbox v-model="checked" checked-color="#ee0a24" />
            <div>
              <p>{{ $t('ebClaim.submitAgreeTitle') }}</p>
              <p class="verify-agree-tc" @click="showTC = true">{{ $t('ebClaim.submitAgreeDesc') }}</p>
            </div>
          </div>
          <div class="verify-btn-group">
            <Button
              class="fu-v2-btn danger block"
              :disabled="(!checked) || String(value).length < 4"
              round
              block
              type="danger"
              @click="checkCode"
            >{{ $t('ebClaim.Submit') }}</Button>
          </div>
        </div>

        <Tc v-if="showTC" v-model="showTC" />
      </div>
    </div>
  </div>

</template>

<script>
import { PasswordInput, NumberKeyboard, Checkbox, Button, Toast } from 'vant'
import portal28 from '@/assets/imgs/portal28.png'
import { sendOtp, validateOtp, submittApplicationForm } from '@/views/claim/api/index.js'
import { Session } from '@/utils/storage'
import { toastLoading } from '@/utils'
import Tc from '../tc/index'

export default {
  components: { Button, PasswordInput, NumberKeyboard, Checkbox, Tc },
  props: { },
  data() {
    return {
      showTC: false,
      portal28,
      value: '',
      checked: false,
      showKeyboard: false,

      isCountDown: false,
      countdownDefaultVal: 60,
      countdownVal: 60
    }
  },
  // mounted() {
  //   // this.sentReportOtp()
  //   // this.submitApplication()
  // },
  activated() {
    this.init()
    // this.sendOtp()
  },
  methods: {
    init() {
      this.value = ''
      this.isCountDown = true
      this.countdownDefaultVal = 60
      this.countdownVal = 60
      this.checked = false
      Toast.success(this.$t('ebClaim.SentSuccess'))
      this.countdown()
    },
    sendOtp() {
      const { bizType } = this.$route.query
      if (bizType == '1') {
        this.sentReportOtp()
      } else {
        this.sentTrackOtp()
      }
    },
    countdown() {
      this.countdownText = this.countdownVal //  + ' S'
      // this.countdownVal--
      this.countdownVal = this.countdownVal - 1 > 9 ? this.countdownVal - 1 : `0${this.countdownVal - 1}`
      // 倒计时结束
      if (this.countdownVal === 0 || this.countdownVal === '00' || isNaN(this.countdownVal)) {
        this.isCountDown = false
        this.countdownText = this.sendDefaultText
        this.countdownVal = this.countdownDefaultVal
        return
      }
      setTimeout(this.countdown, 1000)
    },
    handleAfterSent(res) {
      this.isCountDown = true
      if (res && res.sent) {
        Toast.success(this.$t('ebClaim.SentSuccess'))
        this.countdown()
      } else {
        this.isCountDown = false
      }
    },
    async sentReportOtp() {
      // if (this) { // TODO: 删除
      //   this.validSusubmitApplicationccess()
      //   return
      // }
      const { bizType, claimantIdCard, claimantPhone, policyNo, slipUid, employeeName } = this.$route.query
      this.loading = toastLoading()
      const res = await sendOtp({ bizType, claimantIdCard, claimantPhone, policyNo, slipUid, employeeName, countryCode: 'VN' })
      this.loading && this.loading.clear()
      this.loading = null
      this.handleAfterSent(res)
    },
    async sentTrackOtp() {
      const { bizType, employeeIdCard, employeePhone, employeeName } = this.$route.query
      this.loading = toastLoading()
      const res = await sendOtp({ bizType, employeeIdCard, employeePhone, employeeName, countryCode: 'VN' })
      this.loading && this.loading.clear()
      this.loading = null
      this.handleAfterSent(res)
    },
    async checkCode() {
      const { bizType, claimantPhone, employeePhone, employeeName } = this.$route.query
      const params = {
        bizType, claimantPhone, employeePhone, employeeName,
        otp: this.value
      }
      this.loading = toastLoading()
      const res = await validateOtp(params)
      if (res.code == 200) {
        this.validSuccess()
      } else {
        this.loading && this.loading.clear()
        this.loading = null
      }
    },
    validSuccess() {
      const { bizType } = this.$route.query
      if (bizType == '1') {
        this.submitApplication() // TODO:改去下面
      } else {
        this.loading && this.loading.clear()
        this.loading = null
        const { employeeIdCard: identityNo, employeePhone: phone } = this.$route.query
        this.$router.push({ name: 'EbTrackClaimList', query: {
          bizType, identityNo, phone
        }})
      }
    },
    async submitApplication() {
      const applicationData = Session.get('eb-claim-application-form')
      const formDataJson = JSON.stringify(applicationData)
      const { formNo, reportUid, fileKey } = this.$route.query
      const params = {
        formDataJson, formNo, reportUid, submitPdf: fileKey
      }
      const res = await submittApplicationForm(params)
      if (res.claimFormId) {
        this.$router.push({ name: 'EbClaimSubmitSuccess' })
      }

      this.loading && this.loading.clear()
      this.loading = null
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.verify-div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 91;
  background-color: #fff;
}
.img-dev {
  margin-top: 28px;
  text-align: center;
}
.img1 {
  width: 260px;
}
.verify-t {
  font-size: 14px;
  font-family: 'Montserrat-Bold';
  margin: 15px 0;
}
.verify-s {
  font-size: 12px;
  margin: 0 45px 15px;
  text-align: center;
}
.btn-group {
  box-shadow: 4px 0px 30px 0px #0000001A;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  .verify-btn-group {
    display: flex;
    gap: 10px;
  }
}
::v-deep .van-password-input__security li {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.verify-agree {
  display: flex;
  padding: 10px;
  box-shadow: 0px 4px 20px 0px #0000001A;
  margin-bottom: 10px;
  gap: 20px;
  .verify-agree-tc {
    color: #15B9FF;

  }
}
.otp-sent-dev {
  margin: 20px;
  .resent {
    color: #E74039;
    text-decoration: underline;
  }
  .count-down-number {
    color: #E74039;
  }
}
</style>
